@import '../../base'

/**
 * until https://github.com/facebook/create-react-app/issues/11771 or
 * or https://github.com/facebook/create-react-app/issues/11756
 * or https://github.com/facebook/create-react-app/pull/11764
 * gets fixed, let's hide the problem and not address it
 */

body
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"])
    display: none

.ant-spin-dot
  font-size: 18px

.ant-spin-dot-item
  width: 7px
  height: 7px

.ant-list .ant-list-pagination
  margin-block-start: 6px

textarea.ant-input
  padding: 4px 8px
  line-height: 16px

.ant-form-item-explain-error
  margin: 2px 0

.sound-on-btn.ant-btn, .sound-off-btn.ant-btn
  padding: 4px 0
  min-width: 24px
  width: 24px
  transition: all .2s
  &:hover
    cursor: pointer

.sound-on-btn.ant-btn, .sound-on-btn.ant-btn:hover, .sound-on-btn.ant-btn:focus, .sound-on-btn.ant-btn:active
  background: #e7fce9
  border-color: #93d79c!important
  color: #46af5d!important

.sound-off-btn.ant-btn, .sound-off-btn.ant-btn:hover, .sound-off-btn.ant-btn:focus, .sound-off-btn.ant-btn:active
  background: #fbe9e8
  border-color: #ee6963!important
  color: $tertiary-color!important
  svg
    path
      fill: $tertiary-color

html,
body,
#root
  height: 100%
  margin: 0
  padding: 0
  overflow: hidden

html, body
  font-size: 12px
  font-family: Arial
  line-height: unset

  *
    font-family: Arial !important

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none

  .anticon-reload
    color: $secondary-color
    cursor: pointer
    @include flex

  .ant-select-item-empty
    @include flex
    justify-content: center
    min-height: 40px
    padding: 10px

    .ant-empty .ant-empty-image
      height: 28px

    .ant-spin-spinning
      line-height: 12px

  .ant-select-dropdown
    padding: 0 !important
    border-radius: 2px

    .ant-select-item
      min-height: 30px
      line-height: 12px
      @include flex
      padding: 0 8px
      border-radius: 0

      &:not(:first-of-type)
        border-top: 1px solid #edeff3

    .ant-select-item-option-content, .ant-select-item
      font-size: 12px
      max-width: 100%
      line-height: 13px

      .anticon-check svg
        width: auto
        margin: 0

      svg
        margin-right: 8px
        vertical-align: sub

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(:hover)
    background-color: transparent

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    color: $primary-color
    font-size: 12px
    line-height: 12px
    font-weight: normal!important
    background-color: #f5f5f5 !important

  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover
    border-color: $tertiary-color!important

  .ant-form-inline .ant-form-item-with-help
    margin-bottom: initial

#root
  height: 100%

  .ant-layout
    height: 100%

  label, a, p, div, input, .ant-btn, .ant-form
    font-size: 12px

#root .container, .container
  border-color: #e4e8eb

  .ant-radio-group
    @include flex

    .ant-radio-wrapper
      @include flex
      line-height: 12px
      margin-right: 0

      .ant-radio
        top: unset

  .ant-typography, .ant-card, .ant-form
    color: $primary-color
    line-height: 12px

  .ant-card-bordered
    border-color: #e4e8eb

  .ant-card, .ant-table-wrapper
    height: 100%

  .ant-table-wrapper
    margin: 0 -1px
    max-width: calc(100% + 2px)

    .disabled-drag-row
      cursor: default!important
      .anticon-holder
        opacity: .4

  .ant-table-fixed-header, .ant-table.ant-table-bordered
    height: calc(100% - 30px)
    box-shadow: inset 0 -1px #e4e8eb
    scrollbar-color: unset

    td.table-username-td
      text-decoration: underline
      cursor: pointer
      text-underline-offset: 3px

      > span
        @include flex
        justify-content: space-between

        > span:first-of-type
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 2
          -webkit-box-orient: vertical

          > span
            line-height: 15px

      svg
        font-size: 14px
        line-height: 12px
        min-width: 14px

        &:hover
          fill: $secondary-color

    .ant-table-thead > tr > th[colspan]:not([colspan='1'])
      text-align: left

      .ant-table-column-sorters
        margin-right: 10px

    .ant-table-cell-scrollbar
      box-shadow: none

    .ant-table-body
      box-shadow: inset -1px -1px #e4e8eb
      overflow: auto !important

    .ant-table-container
      border-radius: 0
      border-color: #e4e8eb
      box-shadow: inset -1px 0 0 #e4e8eb
      height: 100%

      *:not(a):not(button):not(.ant-radio-inner):not(.svg-sport-icon svg):not(.ant-checkbox-checked .ant-checkbox-inner)
        border-color: #e4e8eb

    ::-webkit-scrollbar-thumb
      border-right-width: 3px

  .ant-card-head
    min-height: 38px
    font-size: 12px
    padding: 0 10px
    @include flex
    border-color: #e4e8eb
    background: #fafbfd

    .ant-card-head-title
      padding: 0

  .ant-card-body
    padding: 0
    height: calc(100% - 38px)

  .ant-table.ant-table-bordered
    .ant-table-thead .ant-table-cell, .ant-table-header th
      color: $secondary-color
      @include bold
      background: transparent
      height: 35px
      line-height: 14px
      padding: 1px 6px 0
      word-break: keep-all

    .ant-table-cell
      font-size: 12px
      line-height: 15px
      padding: 3px 6px
      height: 38px
      color: $primary-color

    td.ant-table-column-sort
      background: inherit

  .ant-table-expand-icon-col
    width: 0 !important

  .ant-table-row-expand-icon-cell
    padding: 0 !important
    width: 0 !important

    button
      margin-left: 7px
      display: none

  .ant-table-pagination.ant-pagination
    margin: 0 1px
    box-shadow: 0 -1px 0 #e5e9ec
    padding: 4px 10px
    position: relative
    background: #FAFBFD

  .ant-table-column-sorter-up, .ant-table-column-sorter-down
    svg
      height: 12px
      font-size: 10px

.center
  text-align: center !important

.view-mode-tabs
  @include viewModeTabs

.ant-checkbox-wrapper
  @include flex

  .ant-checkbox
    top: auto

    + span
      padding-inline-end: 0
      line-height: 12px

.ant-spin-nested-loading, .ant-spin-container
  height: 100%

  > div > .ant-spin
    max-height: initial

.ant-spin-container
  display: flex
  flex-direction: column
  min-height: 40px

  .ant-spin-blur
    opacity: 0

.page-max-width
  max-width: 830px

.ant-collapse
  border: 0
  background: #fff

  > .ant-collapse-item
    border: 1px solid #d4dae0
    border-radius: 3px

    &:last-of-type
      border-bottom: 1px solid #d4dae0

    &:not(:first-of-type)
      margin-top: 16px

    .ant-collapse-header
      background: #f4f4f4 linear-gradient(to bottom, #f4f4f4 0%, #ededed 100%)
      box-shadow: inset 0 1px #fff
      padding: 9px 12px
      line-height: 12px
      color: $secondary-color
      @include bold
      border-radius: 0

      .ant-collapse-expand-icon
        height: 12px

      .ant-collapse-arrow svg
        width: 9px
        height: 9px

      &:before
        width: 2px
        height: 32px
        background: $secondary-color
        position: absolute
        left: -1px
        top: -1px

    &.ant-collapse-item-active
      .ant-collapse-header:before
        background: $tertiary-color

      .ant-collapse-header
        color: $tertiary-color

  .ant-collapse-content
    border-color: #d4dae0
    background: transparent

    > .ant-collapse-content-box
      padding: 12px 12px 0

.ant-picker-clear
  inset-inline-end: 8px!important

.ant-select
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
    padding: 0 8px

  .ant-select-selection-overflow-item:first-of-type > .ant-select-selection-search
    inset-inline-start: 8px
    inset-inline-end: 8px
  &.ant-select-disabled > .ant-select-arrow
    opacity: .6

  .ant-select-arrow
    right: 6px
    width: 10px
    color: #697e98

    .anticon.anticon-down > svg
      width: 8px
      height: 12px
  .ant-select-clear
    right: 6px

  .anticon-close
    font-size: 10px

  &.ant-select-multiple
    height: 22px
    .ant-select-selection-item
      height: 18px
      line-height: 18px
      margin-block: 0!important
      &:first-of-type
        margin-left: 2px

    .ant-select-selector:after
      content: none
    .ant-select-selection-search
      margin-inline-start: 0
    .ant-select-selection-search-input, .ant-select-selection-search-mirror
      height: 20px
      @include flex

    .ant-select-selection-overflow-item-rest
      margin-inline-end: 0
      .ant-select-selection-item
        padding-inline-start: 4px
        margin-inline-end: 0

      .ant-select-selection-item-content
        margin-inline-end: 0

.ant-form-item-label > label
  height: 22px
  line-height: 12px

.ant-form-item-control-input
  min-height: 22px

.ant-input-affix-wrapper, .ant-select-selector
  border-color: #cbd4da
  font-size: 12px

.ant-input, .ant-input-password, .ant-input-affix-wrapper
  height: 22px
  border-color: #cbd4da
  padding: 0 8px
  vertical-align: top

  input
    height: 100%

.ant-input-search
  width: auto

  .ant-btn
    .anticon-search
      font-size: 14px!important
      color: $secondary-color
    &:hover
      border-color: #cbd4da!important

.ant-picker
  border-color: #cbd4da
  padding: 0 8px

  .ant-picker-input > input
    height: 20px
    width: 66px

  .ant-picker-range-separator
    padding: 0 6px 0 2px

  .ant-picker-separator
    width: 14px
    height: 18px

  &.ant-picker-focused
    box-shadow: 0 0 0 1px rgba(24, 144, 255, 0.2)

  &.ant-picker-range .ant-picker-active-bar
    height: 1px

.ant-input-number
  .ant-input-number-handler-wrap
    width: 16px

  .ant-input-number-input
    padding-right: 20px

  .ant-input-number-handler-down
    border-top: 0
    box-shadow: 0 -1px #e4e8eb

  .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner, .ant-input-number-handler-up-inner svg, .ant-input-number-handler-down-inner svg
    font-size: 7px !important
    height: 7px
    width: 7px

.ant-btn:not(.ant-btn-circle)
  line-height: initial
  padding: 0 8px
  @include flex
  justify-content: center

  &:not(.ant-btn-dangerous):not(.ant-btn-primary):not(.ant-input-search-button):not(:disabled):hover
    border-color: #3a4f66
    color: #3a4f66

  &:disabled svg path
    fill: rgba(0, 0, 0, 0.25) !important

  .anticon
    line-height: initial

.ant-form-item-explain, .ant-form-item-extra
  min-height: auto

  button.ant-pagination-item-link
    @include flex
    justify-content: center

    svg
      width: 10px

.ant-switch.same-switch-color:not(.ant-switch-checked)
  background: #e85454
  border-color: #f8c7ca
  &:not(.ant-switch-disabled):hover
    background: rgba(232, 84, 84, .8)
  &:focus
    box-shadow: 0 0 0 1px rgba(248, 199, 242, .8)

.ant-switch.multiColorSwitch:not(.ant-switch-checked):not(.same-switch-color)
  background: #b8e1c1
  &:not(.ant-switch-disabled):hover
    background: rgba(#b8e1c1, .8)
  .ant-switch-handle::before
    background: #40b05a
  &:focus
    box-shadow: 0 0 0 2px rgba(#40b05a, .1) !important

.ant-switch
  vertical-align: top
  margin: 0 6px 0

  &.ant-switch-checked .ant-switch-handle
    left: calc(100% - 12px)

.ant-modal
  max-height: 80%
  top: 10%
  *:not(svg):not(.ant-spin-dot):not(.anticon-close):not(.arrow):not(.anticon-edit):not(.anticon-delete)
    font-size: 12px

  .ant-modal-content
    border-radius: 3px

  .ant-modal-header
    background: #f4f5f9
    border-bottom: 1px solid #e4e8eb
    height: 35px
    min-height: 35px
    @include flex
    padding: 0 16px
    margin: 0
    border-radius: 3px 3px 0 0

  .ant-modal-close
    top: 5px
    right: 6px
    width: 24px
    height: 24px

    .ant-modal-close-x
      width: 24px
      height: 24px
      line-height: 24px

  .ant-modal-title
    color: $primary-color
    @include bold
    line-height: 12px

  .ant-modal-footer, .ant-modal-confirm-btns
    background: #fafbfd
    border-top: 1px solid #e4e8eb
    height: 35px
    min-height: 35px
    @include flex
    justify-content: center
    margin: 0
    padding: 0 16px
    .ant-btn
      min-width: 60px

.ant-modal-with-table .ant-modal-content .ant-modal-body
  padding: 10px
  .ant-table
    height: 100%

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input-affix-wrapper > input.ant-input:focus
  box-shadow: inset 0 0 0 30px #fff !important
  color: #415271 !important
  -webkit-text-fill-color: #415271 !important

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child
  left: 0

  .ant-input-search-button
    border-left: 0
    box-shadow: none
    border-color: #cbd4da

.icon-close-svg
  width: 10px

  &:hover
    cursor: pointer

    path
      fill: #304865

  &:active path
    fill: #667E9D

.icon-save-svg
  width: 12px

  &:hover
    cursor: pointer

    path
      fill: #CB1F22

  &:active path
    fill: #F97577

.flex
  @include flex

.collapse-buttons
  margin: -2px 0 -10px !important
  width: 100%
  @include flex
  justify-content: center

  .ant-form-item
    margin: 0

  .ant-form-item-control-input-content
    @include flex
    justify-content: center

  button
    min-width: 72px
    margin: 0 4px
    height: 20px !important

.sub-title
  @include bold
  @include flex
  color: $secondary-color
  margin-bottom: 16px
  width: 100%

  > i
    flex: 1
    @include horizontalSeparator($color: $secondary-color)
    margin: 0 0 0 8px

    &:before
      content: ""
      @include horizontalSeparator($color: $secondary-color)
      display: inline-block
      vertical-align: top
      margin-top: 2px

  .anticon.anticon-delete
    color: inherit
    font-size: 14px
    margin-left: 8px

.intl-tel-input
  .phone-widget
    background: transparent
    border: 1px solid #cbd4da
    color: rgba(0, 0, 0, 0.85)
    padding: 0
    border-radius: 2px
    outline: 0
    height: 22px

    &:focus
      border-color: #40a9ff
      outline: 0
      box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)

  .flag-container
    .selected-flag
      position: absolute
      top: 0
      left: 0
      @include flex

    .arrow
      font-size: 8px !important
      color: rgba(0, 0, 0, 0.3)
      line-height: 6px
      @include flex
      margin-top: 2px

    &:hover .selected-flag
      background-color: rgba(0, 0, 0, 0.03) !important

  input, input[type=text], input[type=tel]
    padding: 1px 8px 0 44px !important
    width: 100%

  .country-list
    width: calc(100% + 2px)
    left: 0
    top: 28px
    border-radius: 3px
    z-index: 10

    .country
      list-style: none
      @include ellipsis
      padding: 5px 10px
      color: #171f2d

      &.highlight
        background-color: rgba(0, 0, 0, 0.05)

      &:not(:first-of-type)
        border-top: 1px solid #d9dfe5

  ::-webkit-scrollbar
    width: 8px

  ::-webkit-scrollbar-thumb
    background: rgba(0, 0, 0, 0.5)
    border: 2px solid #fff
    border-radius: 10px

.player-profile-modal
  height: 80%
  max-width: 1186px

  > div
    max-height: 100%
    height: 100%

  .ant-modal-content
    height: 100%

  .ant-modal-body
    padding: 0
    height: 100%

    .player-account
      max-width: 100%
      min-width: 1186px

      .ant-card-body
        padding: 0

      .player-referral > .ant-spin-nested-loading
        height: calc(100% - 110px)

.empty-list
  background: #ecf5fa
  border-radius: 3px
  border: 1px solid #d1dce2
  padding: 0 8px
  @include flex
  justify-content: center
  width: 300px
  height: 32px
  margin: 80px auto 0
  color: #637081

.svg-sport-icon
  min-width: 16px

  > span
    display: flex

  path
    fill: #526b89 !important
  &.sport-29 svg
    border: 1px solid
    border-radius: 100%
    color: #526b89
  &.sport-1 svg
    background: #526b89
    color: #526b89
    border-radius: 100%
    border: 1px solid
    path
      fill: #fff!important

.svg-region-icon
  min-width: 16px
  margin-right: 8px
  height: 13px
  display: inline-block
  vertical-align: top

  > span
    display: flex

.lazy-loading-styles
  width: 100%
  height: -webkit-fill-available
  @include flex
  justify-content: center

.currency-container
  display: inline-flex
  align-items: center
  margin-right: 3px

  svg
    display: inline-block
    vertical-align: top

.vertical-separator
  @include verticalSeparator(14px)
  margin-left: 8px

.bet-status
  display: inline-block

  &.bet-status-win
    width: 12px
    height: 9px
    background: url("../../icons/statuses/bet-status-win.svg") no-repeat center

  &.bet-status-lose, &.bet-status-loss
    width: 9px
    height: 9px
    background: url("../../icons/statuses/bet-status-loss.svg") no-repeat center

  &.bet-status-cancelled
    width: 20px
    height: 14px
    background: url("../../icons/statuses/bet-status-cancelled.svg") no-repeat center

  &.bet-status-void, &.bet-status-rollbackvoid
    width: 12px
    height: 11px
    background: url("../../icons/statuses/bet-status-void.svg") no-repeat center

  &.bet-status-accepted, &.bet-status-pending
    width: 9px
    height: 12px
    background: url("../../icons/statuses/bet-status-open.svg") no-repeat center

  &.bet-status-winvoid
    width: 22px
    height: 12px
    background: url("../../icons/statuses/bet-status-win-void.svg") no-repeat center

  &.bet-status-losevoid
    width: 22px
    height: 12px
    background: url("../../icons/statuses/bet-status-loss-void.svg") no-repeat center

.anticon-info-circle
  color: #6e849b
  height: 14px

  &:hover
    cursor: pointer
    color: $secondary-color

.info-tooltip
  width: auto
  max-width: fit-content
  .ant-tooltip-arrow-content:before
    background: linear-gradient(to left, #077cb2 50%, #077cb2 50%) no-repeat -10px -10px
  &.ant-tooltip-placement-right, &.ant-tooltip-placement-left
    --antd-arrow-background-color: #077cb2
    .ant-tooltip-content
      margin: unset
      .ant-tooltip-inner
        padding: 1px 0

  &.ant-tooltip-placement-right .ant-tooltip-inner
    border-left: 4px solid #077cb2

  &.ant-tooltip-placement-left .ant-tooltip-inner
    border-right: 4px solid #077cb2

  .ant-tooltip-inner
    background: #fff
    padding: 0
    word-wrap: unset
    box-shadow: 0 0 10px rgba(0, 0, 0, .4)
    max-width: 500px
    max-height: 80%
    overflow: auto

    ul
      padding: 0 8px
      list-style: unset
      color: $primary-color
      margin: 0

      li
        @include flex
        min-height: 26px !important
        height: auto!important
        font-size: 12px
        padding: 6px 4px 6px 2px
        line-height: 12px

        &:not(:last-of-type)
          @include horizontalSeparator($color: #b0adbe)

        svg
          margin-right: 8px

        > span
          color: $secondary-color
          margin-left: 10px
          line-height: 12px

.ant-tooltip-content  .ant-tooltip-inner
  padding: 4px 6px
  min-height: auto
  line-height: 12px

.anticon-edit
  opacity: .5

.ui-wallets-dropdown
  width: 110px!important
  .ant-select-selection-item
    min-width: 80px
    max-width: 80px
    padding: 0!important

    svg
      margin-top: 2px
      vertical-align: top
      margin-right: 8px
      min-width: 16px

.ant-tabs-dropdown-menu span
  @include flex
  svg
    min-width: 16px
    margin-right: 6px

.ant-form-item-error-tooltip
  &.ant-form-item-with-help
    margin-bottom: 0
  .ant-form-item-explain
    position: absolute
    margin-top: -46px
    background: rgba(227, 59, 52, 0.9)
    padding: 3px 6px
    min-height: auto
    line-height: 12px
    border-radius: 2px
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)
    height: 18px
    width: auto
    transition: opacity .3s!important
    white-space: nowrap
    z-index: 1

    &:after
      content: ""
      width: 0
      height: 0
      border-style: solid
      border-width: 4px 3px 0 3px
      border-color: rgba(227, 59, 52, 0.9) transparent transparent transparent
      position: absolute
      bottom: -4px

    .ant-form-item-explain-error
      color: #fff
      transition: none!important
      margin: 0

.devices-tabs
  @include flex
  border: 1px solid $tertiary-color
  margin: -1px 0 0 0
  border-radius: 2px
  min-width: 64px
  > span
    flex: 1
    height: 22px
    @include flex
    justify-content: center
    padding: 0 6px
    white-space: nowrap
    color: #526B89
    &:nth-child(2)
      border-left: 1px solid $tertiary-color

    svg
      height: 17px
    &:hover
      cursor: pointer
      box-shadow: inset 0 0 3px rgba($tertiary-color, .8)
    &.selected-device
      background: $tertiary-color
      color: #fff
      .device-desktop, .device-mobile
        fill: #fff

.debounce-select-list
  .ant-spin
    @include flex
    justify-content: center

.form-buttons button
  .icon-close-svg path
    fill: $secondary-color

.lexical-editor
  .editor-inner
    background: #fff
    position: relative

  .editor-input
    min-height: 150px
    resize: none
    font-size: 15px
    caret-color: rgb(5, 5, 5)
    position: relative
    tab-size: 1
    outline: 0
    padding: 15px 10px
    caret-color: #444

  .editor-placeholder
    color: #999
    overflow: hidden
    position: absolute
    text-overflow: ellipsis
    top: 15px
    left: 10px
    font-size: 15px
    user-select: none
    display: inline-block
    pointer-events: none

  .editor-text-bold
    font-weight: bold

  .editor-text-italic
    font-style: italic

  .editor-text-underline
    text-decoration: underline

  .editor-text-strikethrough
    text-decoration: line-through

  .editor-text-underlineStrikethrough
    text-decoration: underline line-through

  .editor-text-code
    background-color: rgb(240, 242, 245)
    padding: 1px 0.25rem
    font-family: Menlo, Consolas, Monaco, monospace
    font-size: 94%

  .editor-link
    color: rgb(33, 111, 219)
    text-decoration: none

  .tree-view-output
    display: block
    background: #222
    color: #fff
    padding: 5px
    font-size: 12px
    white-space: pre-wrap
    margin: 1px auto 10px auto
    max-height: 250px
    position: relative
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px
    overflow: auto
    line-height: 14px

  .editor-code
    background-color: rgb(240, 242, 245)
    font-family: Menlo, Consolas, Monaco, monospace
    display: block
    padding: 8px 8px 8px 52px
    line-height: 1.53
    font-size: 13px
    margin: 0
    margin-top: 8px
    margin-bottom: 8px
    tab-size: 2
    /* white-space: pre */
    overflow-x: auto
    position: relative

  .editor-paragraph
    margin: 0
    margin-bottom: 8px
    position: relative

  .editor-paragraph:last-child
    margin-bottom: 0

  .editor-heading-h1
    font-size: 24px
    color: rgb(5, 5, 5)
    font-weight: 400
    margin: 0
    margin-bottom: 12px
    padding: 0

  .editor-heading-h2
    font-size: 15px
    color: rgb(101, 103, 107)
    font-weight: 700
    margin: 0
    margin-top: 10px
    padding: 0
    text-transform: uppercase

  .editor-list-ol
    padding: 0
    margin: 0
    margin-left: 16px

  .editor-list-ul
    padding: 0
    margin: 0
    margin-left: 16px

  .editor-listitem
    margin: 8px 32px 8px 32px

  .editor-nested-listitem
    list-style-type: none

  pre::-webkit-scrollbar
    background: transparent
    width: 10px

  pre::-webkit-scrollbar-thumb
    background: #999

  .toolbar
    display: flex
    margin-bottom: 1px
    border-bottom: #e1e1e1 1px solid
    padding: 4px
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    vertical-align: middle

  .toolbar button.toolbar-item
    border: 0
    display: flex
    background: none
    border-radius: 10px
    padding: 8px
    cursor: pointer
    vertical-align: middle

  .toolbar button.toolbar-item:disabled
    cursor: not-allowed

  .toolbar button.toolbar-item.spaced
    margin-right: 2px

  .toolbar button.toolbar-item i.format
    background-size: contain
    display: inline-block
    height: 18px
    width: 18px
    margin-top: 2px
    vertical-align: -0.25em
    display: flex
    opacity: 0.6

  .toolbar button.toolbar-item:disabled i.format
    opacity: 0.2

  .toolbar button.toolbar-item.active
    background-color: rgba(223, 232, 250, 0.3)

  .toolbar button.toolbar-item.active i
    opacity: 1

  .toolbar .toolbar-item:hover:not([disabled])
    background-color: #eee

  .toolbar .divider
    width: 1px
    background-color: #eee
    margin: 0 4px

  .toolbar .toolbar-item .text
    display: flex
    line-height: 20px
    width: 200px
    vertical-align: middle
    font-size: 14px
    color: #777
    text-overflow: ellipsis
    width: 70px
    overflow: hidden
    height: 20px
    text-align: left

  .toolbar .toolbar-item .icon
    display: flex
    width: 20px
    height: 20px
    user-select: none
    margin-right: 8px
    line-height: 16px
    background-size: contain
